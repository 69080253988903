import Cookies from 'universal-cookie';
import moment from 'moment-mini';
import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Account {
  constructor() {
    this.cookieName = '__ethosid';
    this.cookieSessionName = '__ethossession';
    this.accountInfo = null;
    this.apiUrl = process.env.SERVER_URL;
  }

  create(url, payload, token) {
    // this should create an account
    // and it should return a bearer token
    const that = this;
    return Ajax.post(url, payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => {
        // PubSub.publish('account_updated');
        that.accountInfo = data;
        return data;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  billing = (url, payload, token) => Ajax.post(url, payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  saveCardDetails = (url, payload, token) => Ajax.post(url, payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  resetPassword = (payload) => Ajax.post(this.apiUrl + 'account/forgotpass', payload, null)
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  recoverPassword = (payload, resetToken, bearerToken) => Ajax.post(this.apiUrl + 'account/resetpass/' + resetToken, payload, { Authorization: bearerToken ? 'Bearer ' + bearerToken : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  changePassword = (payload, token) => Ajax.post(this.apiUrl + 'account/updatepass', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  verifyResetToken = (token) => Ajax.post(this.apiUrl + 'account/resetpassword/verify/' + token, null)
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  update(payload, token) {
    // this should create an account
    // and it should return a bearer token
    return Ajax.put(this.apiUrl + 'account/update', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  login = (payload) => Ajax.post(this.apiUrl + 'account/authenticate', payload)
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  mfaLogin = (payload) => Ajax.post(this.apiUrl + 'account/mfa', payload)
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  createUnverifiedAccount = (payload) => Ajax.post(this.apiUrl + 'account/register/unverified', payload)
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  VerifiedLogin = (payload) => Ajax.post(this.apiUrl + 'account/login-register', payload)
    .then((data) => data)
    .catch((error) => {
    // that.clear();
      throw error;
    })

  get(token, forceUpdate) {
    const that = this;
    if (this.accountInfo !== null && !forceUpdate) {
      return this.accountInfo;
    }
    return Ajax.get(this.apiUrl + 'account', token)
      .then((data) => {
        // that._accountInfo = data;
        // that._aid = that._accountInfo.aid;
        // localStorage.setItem(AID,that._aid);
        // PubSub.publish('account_updated');
        // return that._accountInfo;
        that.accountInfo = data;
        return data;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getCollection(token) {
    return Ajax.get(this.apiUrl + 'wallet/collection', token)
      .then((data) => {
        const dropArray = [];
        const newDropArray = [];
        let newDropArrayObj = {};
        data.forEach((item) => {
          if ((item.creator.creatorId === process.env.CREATOR_ID) || process.env.CREATOR_SUBDOMAIN === 'account') {
            if (!dropArray.includes(item.drop.dropId)) {
              dropArray.push(item.drop.dropId);
              newDropArrayObj = {
                dropId: item.drop.dropId,
                name: item.asset.name,
                nfts: [],
              };
              newDropArray.push(newDropArrayObj);
              newDropArrayObj = {};
            }
          }
        });
        data.forEach((item) => {
          newDropArray.forEach((drop) => {
            if (drop.dropId === item.drop.dropId) {
              drop.nfts.push(item);
            }
          });
        });

        return newDropArray;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getGatedContent = (token, utilityId) => Ajax.get(this.apiUrl + 'utility/gated-content?gatedContentId=' + utilityId, token)
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  getGatedContentV2 = (token, utilityId) => Ajax.get(this.apiUrl + 'utility/gated-content/v2?gatedContentId=' + utilityId, token)
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  getNftDetails(token, nftId) {
    return Ajax.get(this.apiUrl + 'nft/' + nftId, token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getMembershipDetails = (token, userAssetPointsId) => Ajax.get(this.apiUrl + 'loyaltyaccount/' + userAssetPointsId, token)
    .then((data) => data)
    .catch((error) => {
    // that.clear();
      throw error;
    })

  getPreviewDetails = (membershipId, tierId, sessionId) => Ajax.get(this.apiUrl + 'loyaltyaccount/preview/' + membershipId + '/tier/' + tierId + '/categories?sessionId=' + sessionId)
    .then((data) => data)
    .catch((error) => {
    // that.clear();
      throw error;
    })

  userSearch(token, searchTerm) {
    return Ajax.get(this.apiUrl + 'user/search/' + searchTerm, token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  emailSearch(token, email) {
    return Ajax.post(this.apiUrl + 'account/emailexists?email=' + encodeURIComponent(email), null, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  sendLoginEmail(payload) {
    return Ajax.post(this.apiUrl + 'account/authenticate/sendlink', payload)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getLoginToken(payload) {
    return Ajax.post(this.apiUrl + 'account/authenticate/link', payload)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getPaymentMethod(token) {
    return Ajax.get(this.apiUrl + 'payment/method', token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getPaymentTokens(token) {
    return Ajax.get(this.apiUrl + 'payment/PaymentTokens', token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  setPaymentMethod = (token, payload) => Ajax.post(this.apiUrl + 'payment/method', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
    // that.clear();
      throw error;
    })

  isLoggedIn() {
    const cookies = new Cookies();
    if (cookies.get(this.cookieName)) {
      return true;
    }
    return false;
  }

  isVerified() {
    if (this.accountInfo && this.accountInfo.isVerified) {
      return true;
    }
    return false;
  }

  // hasPassword() {

  // }

  hasBirthdate() {
    return this.accountInfo && this.accountInfo.dateOfBirth;
  }

  isBirthMonth() {
    const currentMonth = moment().month();
    if (this.accountInfo && this.accountInfo.dateOfBirth) {
      if (currentMonth === moment(this.accountInfo.dateOfBirth).month()) {
        return true;
      }
      return false;
    }
    return false;
  }

  logout() {
    const cookies = new Cookies();
    cookies.remove(this.cookieName, { path: '/' });
    setTimeout(() => {
      window.location = '/';
    }, 200);
  }

  getSessionId = () => {
    const cookies = new Cookies();
    let sessionId = null;
    if (cookies.get(this.cookieSessionName)) {
      sessionId = cookies.get(this.cookieSessionName);
    }
    return sessionId;
  }

  setSessionId = () => {
    const cookies = new Cookies();
    function uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }
    if (cookies.get(this.cookieSessionName)) {
      RISKX.setSid(sessionStorage.getItem(this.cookieSessionName));
      console.log('=== setting RISKX session Id to: ' + cookies.get(this.cookieSessionName));
    } else {
      cookies.set(this.cookieSessionName, uuidv4(), { path: '/' });
      RISKX.setSid(cookies.get(this.cookieSessionName));
      console.log('=== setting RISKX session Id to: ' + cookies.get(this.cookieSessionName));
    }
  }

  setLoginState(token) {
    const cookies = new Cookies();
    const currentDate = new Date();
    const expiryDate = new Date(currentDate.setHours(currentDate.getHours() + 1));
    cookies.set(this.cookieName, token || 'no token set', {
      path: '/', expires: expiryDate, sameSite: 'strict', secure: true,
    });
  }

  getToken() {
    const cookies = new Cookies();
    return cookies.get(this.cookieName);
  }

  addWallet = (payload, token) => Ajax.post(this.apiUrl + 'wallet/AddWallet', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  generateFreeNft(token, dropId) {
    return Ajax.get(this.apiUrl + 'transfer/FreeNftToken?dropId=' + dropId, token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  transferFreeNFt = (token, payload) => Ajax.post(this.apiUrl + 'transfer/FreeNftToken', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  claimNft = (payload, token) => Ajax.post(this.apiUrl + 'wallet/claim', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      // that.clear();
      throw error;
    })

  transferNFt = (token, payload) => Ajax.post(this.apiUrl + 'transfer/verify', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  transferEdrop = (token, payload) => Ajax.post(this.apiUrl + 'transfer/FreeNftToken', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  syncMembership = (token, payload) => Ajax.post(this.apiUrl + 'loyaltyaccount/sync', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })
  
  transUnlimitedEdrop = (token, payload) => Ajax.post(this.apiUrl + 'transfer/claim/edrop', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  transferToWallet = (token, payload) => Ajax.post(this.apiUrl + 'transfer/Withdraw', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  transferNFtNotification = (token, payload) => Ajax.post(this.apiUrl + 'transfer/notification', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  cancelTransfer = (token, payload) => Ajax.post(this.apiUrl + 'transfer/cancel', payload, { Authorization: token ? 'Bearer ' + token : '' })
    .then((data) => data)
    .catch((error) => {
      throw error;
    })

  getTransferDetails(token, tokenCode) {
    return Ajax.get(this.apiUrl + 'transfer/verify?transferToken=' + tokenCode, token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getTransferDetailsV3 = (token, tokenCode) => Ajax.get(this.apiUrl + 'transfer/edrop/details?transferToken=' + tokenCode, token)
    .then((data) => data)
    .catch((error) => {
    // that.clear();
      throw error;
    })

  getSelectedCard = (cards) => {
    let selectedCard = null;
    if (!cards) {
      return false;
    }
    cards.forEach((card) => {
      if (card.selected) {
        selectedCard = card;
      }
    });
    return selectedCard;
  }

  generateApplePass(token, payload) {
    return Ajax.post(this.apiUrl + 'pass', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => {
        console.log('=== apple pass ===');
        console.log(data);
        return data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getFlatCollection(token, brandId) {
    return Ajax.get(this.apiUrl + 'wallet/collection?creatorId=' + brandId, token)
    // return Ajax.get(this.apiUrl + 'wallet/collection', token)
      .then((data) => {
        const dropArray = [];
        data.forEach((item) => {
          // if (item.creator.creatorId === brandId) {
          //   dropArray.push(item);
          // }
          dropArray.push(item);
        });
        return dropArray;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getReferralLink(token, membershipId) {
    return Ajax.get(this.apiUrl + 'referral/' + membershipId + '/referral-link/type/1', token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getShopReferralLink(token, membershipId) {
    return Ajax.get(this.apiUrl + 'referral/' + membershipId + '/referral-link/type/2', token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getUserLoyaltyPoints(token, loyaltyAccountId) {
    return Ajax.get(this.apiUrl + 'loyaltyaccount/' + loyaltyAccountId + '/points', token)
      .then((data) => data)
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }

  getPointsHistory(token, loyaltyAccountId) {
    return Ajax.get(this.apiUrl + 'loyaltyaccount/' + loyaltyAccountId + '/pointHistory?pageSize=1000', token)
      .then((data) => {
        console.log(data);
        if (data.items) {
          data.items.sort((a, b) => new Date(b.date) - new Date(a.date));
        }
        return data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getInstagramConfiguration(token, assetId) {
    return Ajax.get(this.apiUrl + 'membership/' + assetId + '/configuration', token)
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  creditInstagram(token, payload) {
    return Ajax.post(this.apiUrl + 'loyaltyaccount/creditfollowinstagram', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }
}

export default new Account();
